import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAppNavigation from '@src/shared/lib/hooks/useAppNavigation';
import { IsDefined } from '@src/shared/lib/Helpers';
import { useVerifyEmailMutation } from '@shared/api/auth';
const useEmailConfirm = () => {
    const [verifyEmailReq, { isLoading, isError, isSuccess, },] = useVerifyEmailMutation();
    const { navigate, routes } = useAppNavigation();
    const { token } = useParams();
    const navigateUser = useCallback(() => {
        navigate(routes.HOME_OVERVIEW, { replace: true });
    }, [navigate, routes]);
    useEffect(() => {
        if (IsDefined(token) && token !== '') {
            verifyEmailReq({ verificationToken: token });
        }
    }, [token, verifyEmailReq]);
    useEffect(() => {
        if (isSuccess) {
            navigateUser();
        }
    }, [isSuccess, navigateUser]);
    return {
        isLoading,
        isError,
        isSuccess,
    };
};
export default useEmailConfirm;
